@import "src/themes/mojito/styles/index.scss";
.Banner {
  color: getvar($colors, "neutral", "50");
  background-color: getvar($colors, "primary", "900");
  padding-bottom: space(4.5);
  padding-top: space(4.5);
  text-align: center;

  &__title h2 {
    text-align: center;
    margin-bottom: space(3);
  }

  &--light {
    color: getvar($colors, "neutral", "800");
    background-color: getvar($colors, "primary", "50");
  }
  &--white {
    color: getvar($colors, "neutral", "800");
    background-color: white;
  }

  &--xsmall {
    padding-bottom: space(1);
    padding-top: space(1);

    @include lg {
      padding-bottom: space(1.5);
      padding-top: space(1.5);
    }
  }

  &--small {
    padding-bottom: space(3);
    padding-top: space(3);

    @include lg {
      padding-bottom: space(3.75) !important;
      padding-top: space(3.75) !important;
    }
  }

  &--medium {
    padding-bottom: space(3);
    padding-top: space(3);

    @include lg {
      padding-bottom: space(4.5);
      padding-top: space(4.5);
    }
  }

  &--big {
    padding-bottom: space(2.5);
    padding-top: space(2.5);
    @include sm {
      padding-bottom: space(3);
      padding-top: space(3);
    }
    @include lg {
      padding-bottom: space(6);
      padding-top: space(6);
    }
  }
  &--npPB {
    padding-bottom: 0 !important;
  }
  &--noPT {
    padding-top: 0 !important;
  }
  &--noCenter {
    text-align: start;
  }

  &--primary-800 {
    background-color: getvar($colors, "primary", "800");
  }
  &--primary-600 {
    background-color: getvar($colors, "primary", "600");
  }

  &--primary-100 {
    background-color: getvar($colors, "primary", "100");
  }

  &--neutral-50 {
    background-color: getvar($colors, "neutral", "50");
  }

  &--neutral-white {
    background-color: getvar($colors, "neutral", "white");
  }

  h2 {
    padding: 0 space(1.125);

    @include lg {
      padding: 0;
    }
  }
}

/*lanidng banners*/
.banner-preview {
  padding: 0;
}

.banner-preview.pdf-landing {
  .module-container__header {
    .arrow-svg {
      margin-top: 70px;
      margin-left: 312px;

      @include sm {
        margin-top: 73px;
        margin-left: 434px;
      }
      @include lg {
        margin-top: 69px;
        margin-left: 360px;
      }

      svg {
        height: 65px;

        @include sm {
          height: 70px;
        }
        @include lg {
          height: 76px;
        }
      }
    }
  }
}

.banner-preview.vcard-landing.fastqrgenerator {
  .module-container__header h2 {
    width: 81%;
    font-size: 24px;

    @include sm {
      font-size: 32px;
      width: 90%;
    }

    @include lg {
      width: 100%;
    }
  }
  .module-container__header .arrow-svg {
    margin-top: 81px;
    margin-left: 345px;

    @include sm {
      margin-top: 84px;
      margin-left: 448px;
    }

    @include lg {
      margin-top: 70px;
      margin-left: 396px;
    }

    svg {
      height: 57px;

      @include sm {
        height: 71px;
      }

      @include lg {
        height: 76px;
      }
    }
  }
}
.banner-tabs {
  h2 {
    font-size: space(1.5);

    @include lg {
      font-size: space(2);
    }
  }
  .action-button.tabs {
    margin-top: space(2) !important;
  }
}

.banner-tabs.pdf-landing {
  .landing-tabs {
    @include sm {
      max-width: 685px;
    }

    .nav {
      gap: 0 !important;

      .tab.button.button--secondary {
        width: 168px !important;
        padding: 1rem 6px !important;
      }
    }
    .card-box {
      height: 375px;
      @include sm {
        height: auto;
      }
    }

    .tabs-label {
      @include md {
        margin-right: 0px !important;
        width: 159px !important;
      }
      @include lg {
        margin-right: 12px !important;
      }
    }
  }
  &.fastqrgenerator {
    .landing-tabs {
      @include sm {
        max-width: 691px;
      }
      @include md {
        max-width: 720px !important;
      }
      @include lg {
        max-width: 795px !important;
      }

      .nav .tab.button.button--secondary {
        @include sm {
          width: 172px !important;
        }
        @include md {
          width: 180px !important;
        }

        @include lg {
          width: 180px !important;
        }

        & p {
          font-size: 15px;
          @include sm {
            font-size: 14px;
          }
          @include md {
            font-size: 16px;
          }

          @include lg {
            font-size: 16px;
          }
        }
      }
      .tabs-label {
        @include md {
          margin-right: 0px !important;
          width: 165px !important;
        }
        @include lg {
          margin-right: 12px !important;
        }
      }
    }
  }
}

.banner-cards {
  @include sm {
    padding: 3.8rem 2.5rem;
  }
}

.banner-preview.pdf-landing.fastqrgenerator {
  .module-container__header {
    h2 {
      width: 81%;
      font-size: 24px;

      @include sm {
        width: 78%;
        font-size: 32px;
      }

      @include lg {
        width: 94%;
      }
    }

    p {
      @include sm {
        width: 75%;
      }
      @include lg {
        width: 70%;
      }
    }
    .arrow-svg {
      margin-top: 72px;
      margin-left: 335px;
      @include sm {
        margin-top: 65px;
        margin-left: 379px;
      }
      @include lg {
        margin-top: 69px;
        margin-left: 360px;
      }

      svg {
        height: 65px;

        @include sm {
          height: 70px;
        }
        @include lg {
          height: 76px;
        }
      }
    }
  }
}
